import React, { useRef, useState, useEffect, useCallback } from "react";
import Slider from "react-infinite-logo-slider";
import {
  StepWrapper,
  Wrapper,
  StepCount,
  Background,
  HomeTitle,
  CompanySlider,
} from "./styled";

export const BuildStep = () => {
  const containerRef = useRef(null);
  const [isFocused, setFocused] = useState(false);

  const onObserved = useCallback(
    (entries) => {
      const [entry] = entries;
      !isFocused && setFocused(entry.isIntersecting);
    },
    [isFocused]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(onObserved, {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    });
    let current = null;

    if (containerRef?.current) {
      current = containerRef.current;
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [containerRef, onObserved]);

  return (
    <Background>
      <CompanySlider>
        <Slider
          width="200px"
          duration={60}
          pauseOnHover={true}
          blurBorders={false}
          blurBorderColor={"#fff"}
        >
          <Slider.Slide>
            <img src="/images/companies/tvilu.png" alt="any" className="w-36" />
          </Slider.Slide>
          <Slider.Slide>
            <img
              src="/images/companies/crypso.png"
              alt="any"
              className="w-36"
            />
          </Slider.Slide>
          <Slider.Slide>
            <img
              src="/images/companies/inspectafy.png"
              alt="any"
              className="w-36"
            />
          </Slider.Slide>
          <Slider.Slide>
            <img src="/images/companies/hopx.png" alt="any" className="w-36" />
          </Slider.Slide>
          <Slider.Slide>
            <img src="/images/companies/wad.png" alt="any" className="w-36" />
          </Slider.Slide>
          <Slider.Slide>
            <img src="/images/companies/wergo.png" alt="any" className="w-36" />
          </Slider.Slide>
        </Slider>
      </CompanySlider>
      <HomeTitle>
        <h1>
          Empowering Businesses with Scalable Products and Dedicated Teams That
          Deliver Exceptional Results!
        </h1>
      </HomeTitle>
      <Wrapper ref={containerRef}>
        <div>
          <Step isFocused={isFocused} step={0}>
            Research
          </Step>
          <Step isFocused={isFocused} step={1}>
            Analyse
          </Step>
          <Step isFocused={isFocused} step={2}>
            Develop
          </Step>
          <Step isFocused={isFocused} step={3}>
            Launch
          </Step>
        </div>
      </Wrapper>
      <StepCount>
        <div>
          <div>1</div>
          <div>
            Research and analyse the requirements for the application
            development. Document the initial phase for the implementation and
            setup milestones to deliver in stages
          </div>
        </div>
        <div>
          <div>2</div>
          <div>
            Choose technology stack and architecture for development. Setup
            teams and initiate development. Deliver milestones and complete the
            timeline
          </div>
        </div>
        <div>
          <div>3</div>
          <div>
            Monitor customer behaviour in production releases. Analysis and
            testing toward audiences. Rapid iterations for customer retention
            and marketing.
          </div>
        </div>
      </StepCount>
    </Background>
  );
};

export const Step = ({ children, step, isFocused }) => {
  return (
    <StepWrapper isFocused={isFocused} step={step}>
      {children}
    </StepWrapper>
  );
};

export default BuildStep;
